import React from 'react';
import { Typography } from '@mui/material';
import MuiModal from '../../mui/MuiModal/MuiModal';
import MuiButton from '../../mui/MuiButton';
import MuiTextFieldText from '../../mui/MuiTextField/MuiTextFieldText/MuiTextFieldText';
import styles from './MfaModal.module.scss';

export type MfaModalProps = {
	open: boolean;
	closeModal: () => void;
	login: () => Promise<void>;
	mfaToken: string;
	setMfaToken: ( value: string ) => void;
};

const MfaModal = ( {
	open,
	closeModal,
	login,
	mfaToken,
	setMfaToken,
}: MfaModalProps ) => {
	const handleSubmit = async () => {
		await login();
		closeModal();
	};

	return (
		<MuiModal
			muiModalProps={ {
				open,
				onClose: closeModal,
				PaperProps: { 'data-cy': 'mfa-modal' },
			} }
			title='Please enter your code:'
			controls={
				<MuiButton type='submit' onClick={ handleSubmit }>
					Sign in
				</MuiButton>
			}
		>
			<form
				onSubmit={ ( e ) => {
					e.preventDefault();
					e.stopPropagation();
				} }
				className={ styles.form }
			>
				<Typography>
					We sent you an email with a verification code. The code expires in 15
					minutes.
				</Typography>
				<MuiTextFieldText
					className={ styles.input }
					id='mfa-input'
					value={ mfaToken }
					onChange={ ( e ) => setMfaToken( e.target.value ) }
				/>
			</form>
		</MuiModal>
	);
};

export default MfaModal;
