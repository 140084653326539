import { NextPageContext } from 'next';
import {
	getHostnameAndProtocolFromRequest,
	HandlePreGetServerSideProps,
	PreGetServerSidePropsType,
} from '../blocks/helpers';
import { LoginPageProps, Login } from '../blocks/Login';
import { getCookieString, getInfoFromCookie, Redirect } from '../lib/helpers';

const LoginPage = ( props: PreGetServerSidePropsType<LoginPageProps> ) => (
	<HandlePreGetServerSideProps<LoginPageProps>
		propsFromGetInitialProps={ props }
		ComponentWithoutProps={ Login }
	/>
);

LoginPage.getInitialProps = async ( {
	req,
	res,
	query,
}: NextPageContext ): Promise<PreGetServerSidePropsType<LoginPageProps>> => {
	const hostnameAndProtocol = getHostnameAndProtocolFromRequest( req );
	const cookieString = getCookieString( req );
	if ( cookieString ) {
		const [ , user ] = getInfoFromCookie( cookieString );
		if ( user ) {
			Redirect( res, '/' );
		}
	}

	return {
		props: {
			hostnameAndProtocol,
			returnTo: query.returnTo as string,
			impersonateUser: query.impersonateUser as string,
		},
	};
};

export default LoginPage;
