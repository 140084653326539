import {
	Box,
	Paper,
	FormControl,
	InputLabel,
	Input,
	InputAdornment,
	IconButton,
	Typography,
} from '@mui/material';
import rpcShared from '@rockpapercoin/rpc-shared';
import styles from './Login.module.scss';
import { PageHead } from '../helpers';
import MuiButton from '../../mui/MuiButton';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import Icon from '../../elements/icons';
import Link from 'next/link';
import MFAModal from '../MfaModal';
import { useRouter } from 'next/router';
import { gql } from '@apollo/client';
import validations from '../../lib/helpers/validations';
import {
	useLoginPage_GetAuthedUserLazyQuery,
	useLoginPage_LoginLazyQuery,
	useLoginPage_CreateMfaTokenMutation,
} from './__generated__/';
import { getAuthedUserFlattenedUserFieldsFragment } from '../../types/user';
import { showError } from '../Toast';
import { useDispatch } from 'react-redux';
import { login, toggleLoadingOverlay } from '../../redux/actions';
import { segmentGroup, segmentIdentify } from '../../lib/helpers/segment';
import { setCookie } from '../../lib/helpers';

export type LoginPageProps = {
	hostnameAndProtocol?: string;
	returnTo?: string;
	impersonateUser?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loginMutation = gql`
	query loginPage_login($data: UserLoginInput!) {
		login(data: $data) {
			token
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createImpersonationTokenMutation = gql`
	mutation loginPage_createMfaToken($data: UserLoginInput!) {
		createMfaToken(data: $data)
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getAuthedUserQuery = gql`
	query loginPage_getAuthedUser($where: UserWhereUniqueInput!) {
		getAuthedUser(where: $where) {
			...getAuthedUserFlattenedUserFields
		}
	}
	${ getAuthedUserFlattenedUserFieldsFragment }
`;

type FormValidation = {
	email?: string;
	password?: string;
	mfaToken?: string;
	login?: string;
};

export const Login: React.FC<LoginPageProps> = ( {
	hostnameAndProtocol,
	returnTo,
	impersonateUser,
} ) => {
	const [ showPassword, setShowPassword ] = useState( false );
	const [ mfaToken, setMfaToken ] = useState( '' );
	const [ showMfaModal, setShowMfaModal ] = useState( false );
	const [ password, setPassword ] = useState( '' );
	const [ email, setEmail ] = useState( '' );
	const [ validation, setValidation ] = useState<FormValidation>( {} );
	const [ loginQuery, { loading: loginLoading } ] = useLoginPage_LoginLazyQuery();
	const [ createMfaToken, { loading: mfaLoading } ] =
		useLoginPage_CreateMfaTokenMutation();
	const [ getAuthedUser ] = useLoginPage_GetAuthedUserLazyQuery();
	const router = useRouter();
	const dispatch = useDispatch();
	const validationEnabledRef = useRef( false );

	const onValidate = ( force?: boolean ) => {
		if ( force ) {
			validationEnabledRef.current = true;
		}
		const showValidation = validationEnabledRef.current || force;
		if ( !showValidation ) {
			return true;
		}
		const newValidation = {
			email: validations.validateEmail( email ),
			password: password ? undefined : 'Please enter your password',
		};
		setValidation( newValidation );
		if ( Object.values( newValidation ).some( ( v ) => v ) ) {
			return false;
		}
		return true;
	};

	const onImpersonate = useCallback( async () => {
		const res = await createMfaToken( {
			variables: {
				data: {
					email,
					password,
					targetUserEmail: impersonateUser,
				},
			},
		} );
		if ( res.errors ) {
			res.errors.map( ( error ) => showError( error.message ) );
		} else if ( res.data?.createMfaToken ) {
			setShowMfaModal( true );
		}
	}, [
		impersonateUser,
		createMfaToken,
		email,
		password
	] );

	const onLogin = useCallback( async () => {
		dispatch( toggleLoadingOverlay( true ) );
		const loginRes = await loginQuery( {
			variables: {
				data: {
					email,
					password,
					...( mfaToken && impersonateUser
						? {
							mfaToken: parseInt( mfaToken ),
							targetUserEmail: impersonateUser,
						  }
						: {} ),
				},
			},
		} );
		if ( loginRes.error ) {
			if (
				loginRes.error.message.includes(
					rpcShared.strings.errorMessages.invalidPassword
				) ||
				loginRes.error.message.includes(
					rpcShared.strings.errorMessages.userNotFound
				)
			) {
				setValidation( {
					...validation,
					password: 'You entered an incorrect email, password, or both.',
				} );
			} else {
				showError( loginRes.error.message );
			}
		} else if ( loginRes.data?.login ) {
			setCookie( {
				cookieName: 'Authorization',
				cookieString: loginRes.data?.login?.token,
			} );
			const res = await getAuthedUser( {
				variables: { where: { email: impersonateUser || email } },
			} );
			if ( res.error ) {
				showError( res.error );
			} else if ( res.data?.getAuthedUser ) {
				const user = res.data.getAuthedUser;
				const userForRedux = {
					...( user.clientUser || user.superAdmin || user.orgUser ),
					groups: user?.groups,
				};
				dispatch( login( userForRedux, loginRes.data.login.token ) );
				segmentIdentify( userForRedux );
				segmentGroup( userForRedux );
				setShowMfaModal( false );
				setValidation( {} );
				setPassword( '' );
				setEmail( '' );
				setMfaToken( '' );
				router.push( returnTo || '/' );
			}
		}
		setTimeout( () => dispatch( toggleLoadingOverlay( false ) ), 2000 );
	}, [
		dispatch,
		email,
		getAuthedUser,
		impersonateUser,
		loginQuery,
		mfaToken,
		returnTo,
		password,
		router,
		validation,
	] );

	const handleLogin = async ( e: React.FormEvent<HTMLFormElement> ) => {
		e.preventDefault();
		validationEnabledRef.current = true;
		if ( onValidate() ) {
			if ( impersonateUser && !mfaToken ) {
				onImpersonate();
			} else {
				onLogin();
			}
		}
	};

	useEffect( () => {
		// Prefetch the dashboard page
		const postLoginLocation = returnTo || '/';
		router.prefetch( postLoginLocation );
	}, [ router, returnTo ] );

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const handleMouseUpPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	return (
		<>
			<PageHead
				title='Login'
				hostnameAndProtocol={ hostnameAndProtocol }
				path='/login'
			/>
			<Box className={ styles.container } component='main'>
				<Paper elevation={ 8 } className={ styles.dialogue }>
					<Box className={ styles.headerIcon }></Box>
					<Typography
						variant='h5'
						className={ styles.title }
						data-cy='welcome-back'
					>
						Welcome back!
					</Typography>
					<form className={ styles.form } onSubmit={ handleLogin } noValidate>
						<FormControl variant='standard' className={ styles.input }>
							<InputLabel htmlFor='email'>Email</InputLabel>
							<Input
								id='email'
								data-cy='email-field'
								slotProps={ { input: { type: 'email', inputMode: 'email' } } }
								value={ email }
								onChange={ ( e ) => setEmail( e.target.value ) }
								error={ !!validation.email }
								onBlur={ () => onValidate() }
							/>
							{ validation.email && (
								<Typography variant='body2' className={ styles.error }>
									{ validation.email }
								</Typography>
							) }
						</FormControl>
						<FormControl variant='standard' className={ styles.input }>
							<InputLabel htmlFor='password'>Password</InputLabel>
							<Input
								id='password'
								data-cy='password-field'
								type={ showPassword ? 'text' : 'password' }
								value={ password }
								onChange={ ( e ) => setPassword( e.target.value ) }
								error={ !!validation.password }
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											aria-label={
												showPassword
													? 'hide the password'
													: 'display the password'
											}
											onClick={ () => setShowPassword( !showPassword ) }
											onMouseDown={ handleMouseDownPassword }
											onMouseUp={ handleMouseUpPassword }
											edge='end'
										>
											<Icon
												className={ styles.icon }
												type={ showPassword ? 'visible' : 'visibleOff' }
											/>
										</IconButton>
									</InputAdornment>
								}
							/>
							{ validation.password && (
								<Typography
									data-cy='password-validation'
									variant='body2'
									className={ styles.error }
								>
									{ validation.password }
								</Typography>
							) }
							<Link
								data-cy='forgot-password'
								href={ {
									pathname: '/ResetPassword',
									query: {
										emailAddress: email,
									},
								} }
								as={ `/ResetPassword?emailAddress=${ email }` }
								className={ [ styles.forgotPassword, 'legacyLink' ].join( ' ' ) }
							>
								Forgot your password?
							</Link>
						</FormControl>
						<MuiButton
							className={ styles.submitButton }
							data-cy='sign-in-button'
							type='submit'
							color='primary'
							variant='contained'
							loading={ loginLoading || mfaLoading }
						>
							Sign in
						</MuiButton>
						<Link
							href='/SignUp'
							data-cy='sign-up-link'
							className={ [ styles.noAccount, 'legacyLink' ].join( ' ' ) }
						>
							Don't have an account? Create one now!
						</Link>
					</form>
				</Paper>
			</Box>
			<MFAModal
				open={ showMfaModal }
				closeModal={ () => setShowMfaModal( false ) }
				login={ onLogin }
				mfaToken={ mfaToken }
				setMfaToken={ setMfaToken }
			/>
		</>
	);
};
